import React from 'react'
import { css } from 'glamor'

const LinkSpan = ({ children }) => (
  <span className={`${linkSpanStyle}`} aria-hidden='true'>{children}</span>
);

export default LinkSpan;

let linkSpanStyle = css({
  label: 'linkSpan',
  position: 'absolute',
  zIndex: 101,
  opacity: '0 !important',
  userSelect: 'none',
  WebkitTouchCallout: 'none',
})