import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { css } from 'glamor'
import 'glamor/reset'
import 'typeface-space-mono'
import './layout.css';

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Johan Bisse Mattsson & the in-betweens of graphic design, illustration and coding' },
            { name: 'keywords', content: 'bisse, johan bisse mattsson, bisse mattsson, graphic design, illustration, coding, design, in-betweens' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div id='page' className={`${pageStyle}`}>
          {children}
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;

let pageStyle = css({
  label: 'page',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  '@media (min-height: 360px) and (max-width: 480px), (min-height: 640px) and (min-width: 480px)': {
    position: 'absolute'
  },
})
