import React from 'react'
import { css } from 'glamor'

import ScrollWaypoint from './scrollWaypoint'
import LinkSpan from './linkSpan'

const Footer = ({ waypoint }) => (
    <footer id='footer' className={`${footerStyle}`} role='contentinfo'>
      <ScrollWaypoint waypoint={waypoint}>
        <div className={`${contentStyle}`}>
          <p>&copy; 2018 Johan Bisse Mattsson</p>
          <address className={`${contactStyle}`}>
            <a href='mailto:johan@johanbissemattsson.se'><LinkSpan>johan@johanbissemattsson.se</LinkSpan>johan@johanbissemattsson.se</a>
            <a href='tel:+46708209991'><LinkSpan>+46-708-209991</LinkSpan>+46-708-209991</a>
          </address>
        </div>
      </ScrollWaypoint>
    </footer>

)

export default Footer

let footerStyle = css({
  label: 'footer',
  display: 'flex',
  flex: 1,
  minHeight: '75vh',
  fontSize: '0.6875em',
})

let contentStyle = css({
  label: 'content',
  flex: 1,
  alignSelf: 'flex-end',
  textAlign: 'center',
  margin: '0 auto 2em auto',
  '@media (max-width: 320px)': {
    lineHeight: 1.25
  },
  '& p': {
    margin: '0.5em',
    '@media (max-width: 360px)': {
      margin: 0
    }
  }
})

let contactStyle =  css({
  label: 'contact',
  fontStyle: 'normal',
  '& a': {
    fontWeight: 'bold',
    color: 'var(--color, #000)',
    margin: '0.5em',
    whiteSpace: 'nowrap',
    opacity: 0.8125,
    transition: 'opacity 0.5s, color 0.75s',
    },
    ':hover': {
      opacity: 1
    },
    '@media (max-width: 320px)': {
      display: 'block'
    }
  }
)