import React from 'react'
import { css } from 'glamor'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Footer from '../components/footer'


const NotFoundPage = () => (
  <Layout>
    <div className={`${contentStyle}`}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <p><Link to='/'>Go home</Link></p>
    </div>
    <Footer />
  </Layout>
)

export default NotFoundPage

let contentStyle = css({
  label: 'content',
  flex: 1,
  padding: '5%'
})