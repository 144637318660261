import React, { Component } from 'react';
import { connect } from 'react-redux';
import Waypoint from 'react-waypoint';
import { css } from 'glamor'

import { onEnterWaypoint } from '../actions'

class ScrollWaypoint extends Component {
  constructor(props) {
    super(props);  
    this._onEnterWaypoint = (currentPosition) => {
      const waypointTopOffset = this.waypointInnerRef.offsetTop + ((currentPosition.waypointBottom - currentPosition.waypointTop) / 2);
      this.props.dispatch(
        onEnterWaypoint({character: props.waypoint.character, waypointOffset: waypointTopOffset, selectedWorksHeader: props.waypoint.selectedWorksHeader ? props.waypoint.selectedWorksHeader : false, background: props.waypoint.background})
      ); 
    }
  }
  
  render() {
    const { children, waypoint } = this.props;
    return (
      waypoint ?
        <Waypoint
          onEnter={waypoint.character && this._onEnterWaypoint}
          topOffset='49.9%'
          bottomOffset='49.9%'
        >
          <div className={`${waypointStyle}`} ref={node => this.waypointInnerRef = node} background={waypoint.background} >
            {children}
          </div>
        </Waypoint>
        : 
      children
    );
  }
}

export default connect()(ScrollWaypoint);

let waypointStyle = css({
  label: 'waypoint',
  display: 'inherit',
  flex: '1'
})
